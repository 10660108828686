// miscellaneous stylings 
// width styles
.w-10{
  width: 10%;
}
.w-20{
  width: 20%;
}
.w-30{
  width: 30%;
}
.w-40{
  width: 40%;
}
.w-50{
  width: 50%;
}
.w-60{
  width: 60%;
}
.w-70{
  width: 70%;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-100{
  width: 100%;
}



.w-50p{
  width: 50px;
}
.w-100p{
  width: 100px;
}
.w-150p{
  width: 150px;
}
.w-200p{
  width: 200px;
}
.w-250p{
  width: 250px;
}
.w-300p{
  width: 300px;
}
.w-350p{
  width: 350px;
}
.w-400p{
  width: 400px;
}
.w-450p{
  width: 450px;
}
.w-500p{
  width: 500px;
}
.w-550p{
  width: 550px;
}
.w-600p{
  width: 600px;
}
.w-650p{
  width: 650px;
}
.w-700p{
  width: 700px;
}

// min widths 
.minW-50p{
  min-width: 50px;
}
.minW-100p{
  min-width: 100px;
}
.minW-150p{
  min-width: 150px;
}
.minW-200p{
  min-width: 200px;
}
.minW-250p{
  min-width: 250px;
}
.minW-300p{
  min-width: 300px;
}
.minW-350p{
  min-width: 350px;
}
.minW-400p{
  min-width: 400px;
}
.minW-450p{
  min-width: 450px;
}
.minW-500p{
  min-width: 500px;
}
.minW-550p{
  min-width: 550px;
}
.minW-600p{
  min-width: 600px;
}
.minW-650p{
  min-width: 650px;
}
.minW-700p{
  min-width: 700px;
}


// max widths 
.maxW-50p{
  max-width: 50px;
}
.maxW-100p{
  max-width: 100px;
}
.maxW-150p{
  max-width: 150px;
}
.maxW-200p{
  max-width: 200px;
}
.maxW-250p{
  max-width: 250px;
}
.maxW-300p{
  max-width: 300px;
}
.maxW-350p{
  max-width: 350px;
}
.maxW-400p{
  max-width: 400px;
}
.maxW-450p{
  max-width: 450px;
}
.maxW-500p{
  max-width: 500px;
}
.maxW-550p{
  max-width: 550px;
}
.maxW-600p{
  max-width: 600px;
}
.maxW-650p{
  max-width: 650px;
}
.maxW-700p{
  max-width: 700px;
}


// height styles
.h-50p{
  height: 50px;
}
.h-100p{
  height: 100px;
}
.h-150p{
  height: 150px;
}
.h-200p{
  height: 200px;
}
.h-250p{
  height: 250px;
}
.h-300p{
  height: 300px;
}
.h-350p{
  height: 350px;
}
.h-400p{
  height: 400px;
}
.h-450p{
  height: 450px;
}
.h-500p{
  height: 500px;
}


// min height styles
.minH-50p{
  min-height: 50px;
}
.minH-100p{
  min-height: 100px;
}
.minH-150p{
  min-height: 150px;
}
.minH-200p{
  min-height: 200px;
}
.minH-250p{
  min-height: 250px;
}
.minH-300p{
  min-height: 300px;
}
.minH-350p{
  min-height: 350px;
}
.minH-400p{
  min-height: 400px;
}
.minH-450p{
  min-height: 450px;
}
.minH-500p{
  min-height: 500px;
}
.minH-550p{
  min-height: 550px;
}
.minH-600p{
  min-height: 600px;
}
.minH-650p{
  min-height: 650px;
}
.minH-700p{
  min-height: 700px;
}
.minH-750p{
  min-height: 750px;
}
.minH-800p{
  min-height: 800px;
}
.minH-850p{
  min-height: 850px;
}
.minH-900p{
  min-height: 900px;
}
// padding styles


// texts 
.fs-15p{
  font-size: 15px;
}
.fs-14p{
  font-size: 14px;
}
.fs-13p{
  font-size: 13px;
}
.fs-12p{
  font-size: 12px;
}
.fs-11p{
  font-size: 11px;
}
.fs-10p{
  font-size: 10px;
}
.fs-9p{
  font-size: 9px;
}
.fs-8p{
  font-size: 8px;
}
.fs-7p{
  font-size: 7px;
}
.fs-6p{
  font-size: 6px;
}
.fs-5p{
  font-size: 5px;
}

// home styling 
.home_bg{
  width: 100%;
  height: 100vh;
  background: linear-gradient(#00663fa9, #00663f9c), url(./images/home/bg.png);
  // background: #00663F;
  // background: linear-gradient(#57df0e0e, #5fed1311), url(./images/home/bg.png);
  // background: linear-gradient(), url(./ /resources/images/home/bg.png);
  background-size: cover;


  .home_circle_bg{
    width: 450px;
    height: 450px;
    background-color: #64d6263f;
    border-radius: 300px;
    // position: absolute;
    // top: 100px;
  }
}

// background variants >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 
.bg-green-light{
  background: #c5f5abc9;
  border-color: #c5f5abc9;
}

.bg-green{
  background: #41B883;
  border-color: #41B883;
}
.bg-brand{
  background: #00663F;
  border-color: #00663F;
}
.bg-brand-2{
  background: #0F993E;
  border-color: #0F993E;
}
.bg-lemon{
  background: #5FED13;
  border-color: #5FED13;
}
.bg-yellow{
  background: #FFD500;
  border-color: #FFD500;
}
.bg-brown{
  background: #A08600;
  border-color: #A08600;
}
.bg-custom-lightgold{
  background: linear-gradient(#CCB1141F, #937F0B);
}
.bg-gradient-yellow{
  background: linear-gradient(#FFF5C3 12%, #D4B30B 100%);
}
.bg-custom-green{
  background: linear-gradient(#98D60666 12%, #7DA816 100%);
}
.bg-custom-auth{
  background: linear-gradient(to right top, #00663F, #5FED13)
}
.bg-custom-red{
  background: linear-gradient(#ff00001f 12%, #BF0E0E 100%);
}
.bg-custom-orange{
  background: linear-gradient(#ffcc6d 12%, #FFA500 100%);
  border-color: #E89B0E
}
.bg-custom-blue{
  background: linear-gradient(#99c1f5 12%, #197EFF 100%,);
  border-color: #084088
}
.bg-custom-brown{
  background: linear-gradient(#e7d67e 12%,#A08600 100%)
}
.bg-custom-purple{
  background: linear-gradient(#FF0DAF21, #900A64) ;
}
.bg-custom-blue{
  background: linear-gradient(#14C6CC1F, #004345) ;
}


.img-logo-1{
  opacity: 0.1
}
.text-brand-light{
  color: #42b25c;
}
.text-brand {
  color: #00663F;
}
.text-lemon {
  color: #5FED13;
}

.rounded-15p{
  border-radius: 15px;
}
.rounded-summary-card{
  border-radius: 45px 0px 45px 0px;
}

.pt-10p{
  padding-top: 10px;
}
.pt-20p{
  padding-top: 20px;
}
.pt-30p{
  padding-top: 30px;
}
.pt-40p{
  padding-top: 40px;
}
.pt-50p{
  padding-top: 50px;
}
.pt-60p{
  padding-top: 60px;
}
.pt-70p{
  padding-top: 70px;
}
.pt-80p{
  padding-top: 80px;
}
.pt-90p{
  padding-top: 90px;
}
.pt-100p{
  padding-top: 100px;
}


.py-10p{
  padding: 10px auto;
}
.py-20p{
  padding: 20px auto;
}
.py-30p{
  padding: 30px auto;
}
.py-40p{
  padding: 40px auto;
}
.py-50p{
  padding: 50px auto;
}
.py-60p{
  padding: 60px auto;
}
.py-70p{
  padding: 70px auto;
}
.py-80p{
  padding: 80px auto;
}
.py-90p{
  padding: 90px auto;
}
.py-100p{
  padding: 100px auto;
}


.wt{
  width: 100%; 
  @media (min-width: 600px) {
    width: 50%;
  }
  @media (min-width: 1012px) {
    width: 30%;
  } 
}

.nav-item:hover{
  border-bottom: 1px solid #e0e0e0;
}

.topBar-clock{
  width: 100%;
  background: url(/public/resources/images/icons/clock.png);
  background-size: cover;
}

.btn-action:hover{
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
}

.user-card{
  background: url(./images/card/blank.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.cursor-disabled{
  cursor: not-allowed;
}

.border-custom{
  border-radius: 20px 0px;
}